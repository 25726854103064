.jobs {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    margin-top: 10px;

    .jobsTitle { 
        margin: 0.5rem 0;
        font-size: 2.2rem; 
    }

   
    .job {
        margin-top: 10px;
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;
        margin-bottom: 2rem;
        img {
            max-width: 450px;
        }
        &:nth-child(odd)  {
            flex-direction: row-reverse;   
            h1 {
                text-align: right;
            } 
        } 
        &__text {
            text-align: justify;
            max-width: 400px;
            h1 {
                line-height: 30px;
                margin-bottom: 1rem;
                font-size: 1.8rem;
                text-align: left;
                text-decoration: underline;
            }
            a{ 
                color: inherit;  
                text-decoration: inherit; 
            }
        }


      
    }

    @media screen and (max-width:800px) {
        .job { 
            flex-direction: column !important;
            h1 {
                text-align: center !important; 
            }
            *:not(h1) { 
                text-align: justify !important;
            }
            &__text {
                text-align: justify;
                max-width: 100vw;
            }

            img {
                max-width: 92vw;
            }
        }
    }
}