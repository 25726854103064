 

.SideBarContent { 
    width: 22vw; 
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align:  justify; 
    overflow: hidden;
    .profile { 
        margin-top: 1rem;
        & img {
            width: 50%;
            border-radius: 50%;
            
        }
        text-align: center;
    }
    .SideBarText {
        h2 {
            margin-bottom: 1rem;
            text-align: center;
        }
        padding: 5px 1rem; 
        overflow-y: scroll;   
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        
        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
    }
    .icons {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: auto;
        margin-bottom: 10px;
        >*{
            cursor: pointer;
        }
        img {
            width: 50px}
    }

    @media screen and (max-width:800px) {
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0 10px; 
        overflow: scroll;  
        .profile { 
            text-align: center;
            margin-top: 0;
            & img {
                width: 50%;
                padding: 10px; 
                border-radius: 50%; 
            }
        }
        .SideBarText { 
            padding-bottom: 10px;
        }
        .icons {
            margin-top: 5px;
            margin-bottom: 0;
            flex-direction: row-reverse;
        }
        :nth-child(1) { order: 2; }
        :nth-child(2) { order: 3; }
        :nth-child(3) { order: 1; }
      }
}

 .menuButton {
    position: absolute;
    top:0;
    left:0;
    padding: 1rem;
    max-width: 30px;
  }