@import  './styles/_colors.scss';
.App { 
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  overflow-y: auto;

  .Main { 
    margin-top: 20px;
    width: 100vw;
    height: calc(100vh - 40px);
    padding: 0 1rem;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;

    .players {
      display: flex;
      justify-content: space-between;
      .player {
        width: 48%;
        h2 {
          margin-bottom: 5px;
        }
      }
    }

    @media screen and (max-width:800px) {
      width: 100vw;
      overflow: scroll;  
      margin-left: 0; 
      text-align: center;
      .players{
        flex-direction: column;
        .player{
          width: 100%;
          margin-bottom: 10px;
        }
      } 
    }
  }
  footer {
    text-align: center;
    width: 100%;
  }
}


aside {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  border: none !important;
}


// Theme variants
.light {
  color: $purple-700;
  background: linear-gradient(180deg, $white 0%, $purple-300 100%);
  .SideBarContent {
    background: linear-gradient(180deg, $white 0%, $purple-300 100%);
  }  
  .icon  {
    filter: invert(30%) sepia(37%) saturate(596%) hue-rotate(203deg) brightness(91%) contrast(93%);
  }
}
.dark {
  background: linear-gradient(180deg, $purple-600 50%, $purple-700 100%);
  color: $purple-300;
  .SideBarContent {
    background: linear-gradient(180deg, $purple-600 50%, $purple-700 100%); 
  }
  .icon  {
    filter: invert(83%) sepia(16%) saturate(433%) hue-rotate(296deg) brightness(104%) contrast(87%);
  }
}
 
